import { defineStore } from 'pinia';

export const useSimulationStore = defineStore('simulation', {
    state: () => ({
        id: null as number | null,
    }),
    getters: {
        getId(): any {
            return this.id;
        },

    },
    actions: {
        setId(id: any) {
            this.id = id;
        },
    },
    persist: true,
});
