<template>
    <v-app>
        <router-view />
        <v-snackbar
            v-if="showAlert"
            @update:model-value="hideAlert"
            :model-value="showAlert"
            :color="getAlert.color"
            :variant="getAlert.variant"
            :timeout="getAlert.timeout"
            multi-line>
            {{ getAlert.message }}
        </v-snackbar>
    </v-app>
</template>

<script setup lang="ts">
    import { useAlert } from '@/composables/alert';
    import { ref, watch, nextTick, onMounted } from 'vue';

    const { getAlert, hideAlert } = useAlert();

    const showAlert = ref(false);

    watch(
        getAlert,
        (newVal) => {
            if (newVal.show) {
                showAlert.value = false;
                nextTick(() => {
                    showAlert.value = true;
                });
            } else {
                showAlert.value = false;
            }
        },
        {
            deep: true,
        },
    );
</script>
