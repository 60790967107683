<template>
    <v-container>
        <div class="my-10 text-center">
            <v-row>
                <v-col cols="12">
                    <p class="text-primary text-h4 font-weight-bold">CIE L*a*b* Calculator</p>
                </v-col>
            </v-row>
        </div>

        <v-row>
            <v-col
                v-for="(color, index) in colors"
                :key="index"
                cols="12"
                md="6">
                <v-card style="margin: auto">
                    <v-responsive
                        :style="{ background: currentColor2(color) }"
                        height="100px"></v-responsive>

                    <v-card-text>
                        <v-slider
                            v-model="color.lightness"
                            :max="100"
                            :min="0"
                            :step="1"
                            label="L* (Lightness):"
                            hide-details
                            class="ma-4">
                            <template v-slot:append>
                                <v-text-field
                                    v-model="color.lightness"
                                    type="number"
                                    style="width: 80px"
                                    density="compact"
                                    hide-details
                                    variant="outlined"></v-text-field>
                            </template>
                        </v-slider>

                        <v-slider
                            v-model="color.a"
                            :max="127"
                            :min="-128"
                            :step="1"
                            label="a* (Green to Red):"
                            hide-details
                            class="ma-4">
                            <template v-slot:append>
                                <v-text-field
                                    v-model="color.a"
                                    type="number"
                                    style="width: 80px"
                                    density="compact"
                                    hide-details
                                    variant="outlined"></v-text-field>
                            </template>
                        </v-slider>

                        <v-slider
                            v-model="color.b"
                            :max="127"
                            :min="-128"
                            :step="1"
                            label="b* (Blue to Yellow):"
                            hide-details
                            class="ma-4">
                            <template v-slot:append>
                                <v-text-field
                                    v-model="color.b"
                                    type="number"
                                    style="width: 80px"
                                    density="compact"
                                    hide-details
                                    variant="outlined"></v-text-field>
                            </template>
                        </v-slider>
                        <v-slider
                            v-model="color.percentage"
                            :max="100"
                            :min="0"
                            @update:model-value="changedPercentage(color, index)"
                            :step="1"
                            label="Percentage:"
                            hide-details
                            class="ma-4">
                            <template v-slot:append>
                                <v-text-field
                                    v-model="color.percentage"
                                    type="number"
                                    style="width: 80px"
                                    density="compact"
                                    hide-details
                                    variant="outlined"></v-text-field>
                            </template>
                        </v-slider>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card style="margin: auto">
                    <v-responsive
                        :style="{ background: currentColor2(mixCIELABColorsWithPercentages(colors[0], colors[1])) }"
                        height="100px"></v-responsive>

                    <v-card-text>
                        <v-slider
                            v-model="mixCIELABColorsWithPercentages(colors[0], colors[1]).lightness"
                            :max="100"
                            :min="0"
                            :step="1"
                            label="L* (Lightness):"
                            hide-details
                            class="ma-4">
                            <template v-slot:append>
                                <v-text-field
                                    v-model="mixCIELABColorsWithPercentages(colors[0], colors[1]).lightness"
                                    type="number"
                                    style="width: 80px"
                                    density="compact"
                                    hide-details
                                    variant="outlined"></v-text-field>
                            </template>
                        </v-slider>

                        <v-slider
                            v-model="mixCIELABColorsWithPercentages(colors[0], colors[1]).a"
                            :max="127"
                            :min="-128"
                            :step="1"
                            label="a* (Green to Red):"
                            hide-details
                            class="ma-4">
                            <template v-slot:append>
                                <v-text-field
                                    v-model="mixCIELABColorsWithPercentages(colors[0], colors[1]).a"
                                    type="number"
                                    style="width: 80px"
                                    density="compact"
                                    hide-details
                                    variant="outlined"></v-text-field>
                            </template>
                        </v-slider>

                        <v-slider
                            v-model="mixCIELABColorsWithPercentages(colors[0], colors[1]).b"
                            :max="127"
                            :min="-128"
                            :step="1"
                            label="b* (Blue to Yellow):"
                            hide-details
                            class="ma-4">
                            <template v-slot:append>
                                <v-text-field
                                    v-model="mixCIELABColorsWithPercentages(colors[0], colors[1]).b"
                                    type="number"
                                    style="width: 80px"
                                    density="compact"
                                    hide-details
                                    variant="outlined"></v-text-field>
                            </template>
                        </v-slider>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-btn @click="updateView()">Atualizar Visualização</v-btn>
            </v-col>
            <v-col cols="12">
                <div class="tw-flex tw-justify-center">
                    <TextileCone
                        :width="windowWidth * 0.9"
                        :ref="textileCone"
                        :trigger="trigger_"
                        :color="
                            labToHex(
                                mixCIELABColorsWithPercentages(colors[0], colors[1]).lightness,
                                mixCIELABColorsWithPercentages(colors[0], colors[1]).a,
                                mixCIELABColorsWithPercentages(colors[0], colors[1]).b,
                            )
                        " />
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import TextileCone from '@/components/TextileCone.vue';
    import { lab } from 'd3-color';

    const textileCone = ref();
    const trigger_ = ref(0);

    const windowWidth = computed(() => window.innerWidth);

    function updateView() {
        trigger_.value += 1;
    }

    function labToHex(L: Number, a: Number, b: Number) {
        // Create a LAB color object
        const labColor = lab(L, a, b);

        // Convert LAB to RGB
        const rgbColor = labColor.rgb();

        // Convert RGB to hexadecimal
        const hexColor = rgbColor.formatHex();

        return hexColor;
    }

    let colors = ref([
        { lightness: 0, a: 0, b: 0, percentage: 50 },
        { lightness: 100, a: 0, b: 0, percentage: 50 },
        //{ lightness: 53, a: 80, b: 67, percentage: 100 / 3 },
    ]);

    let currentColor2 = (color: any) => {
        return `lab(${color.lightness}, ${color.a}, ${color.b})`;
    };

    // Function to mix two CIELAB colors based on percentages for each color
    function mixCIELABColorsWithPercentages(color1: any, color2: any) {
        // Convert percentages to decimals
        const weight1 = color1.percentage / 100;
        const weight2 = color2.percentage / 100;

        // extract components of each color
        const l1 = color1.lightness;
        const a1 = color1.a;
        const b1 = color1.b;
        const l2 = color2.lightness;
        const a2 = color2.a;
        const b2 = color2.b;

        console.log(l1, a1, b1, l2, a2, b2);

        // Calculate weighted averages for each component
        const resultL = weight1 * l1 + weight2 * l2;
        const resultA = weight1 * a1 + weight2 * a2;
        const resultB = weight1 * b1 + weight2 * b2;

        // Return the resulting CIELAB color
        return { lightness: resultL, a: resultA, b: resultB };
    }

    function changedPercentage(color: any, index: number) {
        //colors.value[index].percentage = color.percentage;
        colors.value[index == 0 ? 1 : 0].percentage = 100 - color.percentage;
    }
</script>

<style></style>
