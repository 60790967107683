export const theme = {
    colors: {
        transparent: '#FFFFFF00',
        current: 'currentColor',
        white: '#FFFFFF',
        black: '#000000',
        primary: {
            100: '#cffafe',
            200: '#a5f3fc',
            300: '#67e8f9',
            400: '#22d3ee',
            DEFAULT: '#008676',
            600: '#0891b2',
            700: '#0e7490',
            800: '#155e75',
            900: '#164e63',
        },
        red: {
            100: '#fee2e2',
            200: '#fecaca',
            300: '#fca5a5',
            400: '#f87171',
            DEFAULT: '#ef4444',
            600: '#dc2626',
            700: '#b91c1c',
            800: '#991b1b',
            900: '#7f1d1d',
        },
        green: {
            100: '#d1fae5',
            200: '#a7f3d0',
            300: '#6ee7b7',
            400: '#34d399',
            DEFAULT: '#10b981',
            600: '#059669',
            700: '#047857',
            800: '#065f46',
            900: '#064e3b',
        },
        gray: {
            100: '#F9F9F9',
            200: '#f3f4f6',
            300: '#e5e7eb',
            400: '#d1d5db',
            DEFAULT: '#9ca3af',
            600: '#6b7280',
            700: '#4b5563',
            800: '#374151',
            900: '#1f2937',
        },
    },
    fontFamily: {
        sans: ['Montserrat', 'sans-serif'],
    },
} as any;

export default theme;

export function flatColors() {
    const flatColors: any = {};
    for (const color in theme.colors) {
        if (['transparent', 'current'].includes(color)) continue;
        if (typeof theme.colors[color] === 'string') {
            flatColors[color] = theme.colors[color];
        } else {
            for (const shade in theme.colors[color]) {
                if (shade != 'DEFAULT') flatColors[`${color}-${shade}`] = theme.colors[color][shade];
                else flatColors[color] = theme.colors[color][shade];
            }
        }
    }
    return flatColors;
}
