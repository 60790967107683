import moment from 'moment';

export function useDate(date: Date | null | string | undefined) {
    const formattedDate = (() => {
        if (date) {
            return moment(date).format('DD/MM/YYYY, HH:MM ');
        } else {
            return 'N/A';
        }
    })();

    return {
        formattedDate,
    };
}