import { defineStore } from 'pinia';
import { computed, ref, nextTick } from 'vue';
import _ from 'lodash';

export interface IAlert {
    show?: boolean;
    message: string;
    variant?: 'flat' | 'text' | 'elevated' | 'tonal' | 'outlined' | 'plain' | undefined;
    color?: string;
    timeout?: number;
}

const defaultAlert: IAlert = {
    show: false,
    message: '',
    variant: 'outlined',
    color: 'success',
    timeout: 3000,
};

export const alertStore = defineStore({
    id: 'alertStore',
    state: () => ({
        alert: {
            show: false,
            message: '',
            variant: 'outlined',
            color: 'success',
            timeout: 3000,
        } as IAlert,
    }),
    getters: {
        getAlert: (state) => {
            return state.alert;
        },
    },
    actions: {
        setAlert(data: IAlert) {
            this.alert = data;
        },
    },
});

export const useAlert = () => {
    const store = alertStore();

    function showAlert(data: IAlert) {
        data.show = true;
        store.setAlert(_.assign(_.clone(defaultAlert), data));
    }

    function hideAlert() {
        store.setAlert(defaultAlert);
    }

    const getAlert = computed(() => {
        return store.getAlert;
    });

    return {
        showAlert,
        getAlert,
        hideAlert,
    };
};
