<template>
    <v-navigation-drawer v-model="isOpen">
        <v-list-item
            prepend-avatar="https://randomuser.me/api/portraits/men/78.jpg"
            title="John Leider"></v-list-item>
        <v-divider></v-divider>
        <v-list
            density="compact"
            nav>
            <v-list-item
                prepend-icon="mdi-folder"
                title="Simulações"
                value="simulations"></v-list-item>
            <v-list-item
                prepend-icon="mdi-account-multiple"
                title="Shared with me"
                value="shared"></v-list-item>
            <v-list-item
                prepend-icon="mdi-star"
                title="Starred"
                value="starred"></v-list-item>
        </v-list>
        <template v-slot:append>
            <div class="tw-p-2">
                <v-btn
                    @click="logout"
                    block
                    class="tw-cursor-pointer"
                    color="primary">
                    Logout
                </v-btn>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
    import { ref, watch, onBeforeUnmount } from 'vue';
    import { useAppStore } from '../store/app';
    import { useAuthStore } from '@/store/auth';
    import { useRouter } from 'vue-router';

    const appStore = useAppStore();
    const authStore = useAuthStore();
    const $router = useRouter();

    const isOpen = ref(false);

    watch(
        () => appStore.isSidebarOpen,
        (value) => {
            isOpen.value = value;
        },
    );

    watch(
        () => isOpen.value,
        (value) => {
            appStore.setIsSidebarOpen(value);
        },
    );

    onBeforeUnmount(() => {
        appStore.setIsSidebarOpen(false);
    });

    async function logout() {
        authStore.$reset();
        $router.push({ name: 'Login' });
    }
</script>

<style scoped></style>
