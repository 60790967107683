import { useI18n } from 'vue-i18n';

export default function useRules() {
    const i18n = useI18n();
    return {
        required(value: string | number | boolean) {
            return !!value || i18n.t('rules.required');
        },
        email(value: string) {
            // eslint-disable-next-line
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()\[\]\\.,;:\s@"]+\.)+[^<>()\[\]\\.,;:\s@"]{2,})$/i;
            return re.test(value) || i18n.t('rules.email');
        },
        min(value: any, min: number) {
            return value.length >= min || i18n.t('rules.min', [min]);
        },
        max(value: any, max: number) {
            return value.length <= max || i18n.t('rules.max', [max]);
        },
        between(value: any, min: number, max: number) {
            return (value.length >= min && value.length <= max) || i18n.t('rules.between', [min, max]);
        },
        comparePassword(value: string, other: string) {
            return value === other || i18n.t('rules.comparePassword');
        },
        greaterThan(value: number, other: number) {
            return Number(value) > Number(other) || i18n.t('rules.greaterThan', [other]);
        },
        lessThan(value: number, other: number) {
            return Number(value) < Number(other) || i18n.t('rules.lessThan', [other]);
        },
        greaterThenEqual(value: number, other: number) {
            console.log(value);
            console.log(other);

            return Number(value) >= Number(other) || i18n.t('rules.greaterThenEqual', [other]);
        },
        lessThenEqual(value: number, other: number) {
            console.log(value);
            console.log(other);
            return Number(value) <= Number(other) || i18n.t('rules.lessThenEqual', [other]);
        },
    };
}