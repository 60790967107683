<template>
    <v-app-bar color="gray-300">
        <template v-slot:prepend>
            <!-- <v-app-bar-nav-icon @click="openSidebar"></v-app-bar-nav-icon> -->
        </template>
        <!-- <v-app-bar-title>APP</v-app-bar-title> -->
        <v-img
            @click="goMain"
            src="@/assets/images/logo2.png"
            class="tw-cursor-pointer"
            aspect-ratio="1"></v-img>
        <v-btn v-if="authStore.isAuthenticated" @click="logout"  prepend-icon="mdi-logout"> Logout </v-btn>
    </v-app-bar>
</template>

<script setup lang="ts">
    import { ref, watch } from 'vue';
    import { useAppStore } from '@/store/app';
    import { useAuthStore } from '@/store/auth';
    import { useRouter } from 'vue-router';

    const authStore = useAuthStore();
    const $router = useRouter();
    const appStore = useAppStore();

    function openSidebar() {
        appStore.setIsSidebarOpen(true);
    }

    function logout() {
        authStore.resetUser();
        $router.push({ name: 'Login' });
    }

    function goMain() {
        if (authStore.isAuthenticated)
            $router.push({ name: 'Simulations' });
        else
            $router.push({ name: 'CreateSimulation2' });
    }
</script>

<style scoped></style>
