<template>
    <div class="tw-px-8">
        <div class="container-parent d-flex align-center justify-center tw-my-8">
            <h2 class="text-primary text-h4 font-weight-bold">Simulações</h2>
        </div>
        <div>
            <v-container
                fluid
                class="flex-column align-start">
                <v-row>
                    <v-col
                        cols="12"
                        md="6">
                        <v-text-field
                            @update:modelValue="fetchSimulations"
                            label="Pesquisar"
                            prepend-inner-icon="mdi-magnify"
                            hint="Insira o texto a pesquisar"></v-text-field>
                    </v-col>
                    <v-col
                        class="tw-px-4"
                        cols="12"
                        md="3">
                        <v-select
                            label="Ordernar Por"
                            v-model="sort"
                            @update:modelValue="changeSort"
                            :items="['Alfabeticamente', 'Data de criação']"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        @click="$router.push({ name: 'SimulationDetail', params: { id: simulation.id } })"
                        cols="6"
                        md="4"
                        lg="3"
                        class="tw-cursor-pointer"
                        v-for="simulation in simulations"
                        :key="simulation.id">
                        <SimulationCard :simulation="simulation"></SimulationCard
                    ></v-col>
                </v-row>
            </v-container>
        </div>
        <!-- <v-btn
            @click="$router.push({ name: 'CreateSimulation' })"
            fab
            right
            x-large
            absolute
            bottom
            fixed
            icon="mdi-plus"
            class="fab tw-cursor-pointer"></v-btn> -->
    </div>
</template>
<script setup lang="ts">
    import SimulationCard from '@/components/SimulationCard.vue';
    import { Simulation } from '@/types';
    import { ref } from 'vue';
    import axios from '@/plugins/axios';
    import qs from 'qs';

    let simulations = ref<Simulation[]>([]);

    let sort = ref(null);

    async function changeSort() {
        let query;
        if (sort.value == 'Alfabeticamente') {
            query = {
                sort: ['name:desc'],
            };
        } else {
            query = {
                sort: ['createdAt:asc'],
            };
        }

        const { data } = await axios.get(`/simulations?${qs.stringify(query)}`);
        simulations.value = data.data;
    }

    async function fetchSimulations(string: string = '') {
        if (string) {
            if (string.length >= 3 || string.length == 0) {
                let query = {
                    filters: {
                        $or: [
                            {
                                name: {
                                    $containsi: string,
                                },
                            },
                            {
                                email: {
                                    $containsi: string,
                                },
                            },
                            {
                                user_name: {
                                    $containsi: string,
                                },
                            },
                        ],
                    },
                };
                const { data } = await axios.get(`/simulations?${qs.stringify(query)}`);
                simulations.value = data.data;
            }
        } else {
            const { data } = await axios.get('/simulations');
            simulations.value = data.data;
        }
    }

    fetchSimulations();
</script>
<style scoped>
    .v-btn.fab {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 1000;
        height: 64px;
        width: 64px;
        background-color: #008676;
        color: #ffff;
    }
</style>
