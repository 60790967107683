import axios from 'axios';
import { useAuthStore } from '@/store/auth';
import router from '@/router';

const instance = axios.create({
    baseURL: import.meta.env.VITE_API_URL + '/api',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = useAuthStore().getToken;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            router.push({ name: 'Login' });
            useAuthStore().resetUser();
        }
        return Promise.reject(error);
    }
);

export default instance;
