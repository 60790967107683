<template>
    <div ref="canvasContainer"></div>
</template>

<script setup lang="ts">
    import { ref, onMounted, onBeforeUnmount, defineProps, watch } from 'vue';
    import * as THREE from 'three';
    import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

    // receive prop color
    const props = defineProps({
        trigger: {
            type: Number,
            default: 0,
        },
        color: {
            type: String,
            default: '#146d46',
        },
        width : {
            type: Number,
            default: 0.9,
        },
    });

    const canvasContainer = ref(null);
    let scene, camera, renderer, outerCylinder, springLine;

    const initThree = (color) => {
        // Dispose of existing objects
        disposeObject(outerCylinder);
        disposeObject(springLine);

        // Clear the canvas container
        canvasContainer.value.innerHTML = '';

        // Create a new scene
        scene = new THREE.Scene();

        // Configurar a câmera
        const aspectRatio = window.innerWidth / window.innerHeight;
        const newWidth = props.width; // Set your desired width
        const newHeight = newWidth / aspectRatio;

        camera = new THREE.PerspectiveCamera(75, aspectRatio, 0.1, 1000); // Parameters: FOV, aspect ratio, near, far
        camera.position.z = 4;

        // Configurar o renderizador
        renderer = new THREE.WebGLRenderer();
        renderer.setSize(newWidth, newHeight);
        canvasContainer.value.appendChild(renderer.domElement);

        // Criar geometria do cilindro externo
        const outerGeometry = new THREE.CylinderGeometry(0.25, 0.8, 3.5, 64, 1, true); // Parameters: radiusTop, radiusBottom, height, radialSegments, heightSegments, openEnded
        const outerMaterial = new THREE.MeshBasicMaterial({ color: 0x946d46 });
        outerCylinder = new THREE.Mesh(outerGeometry, outerMaterial);

        scene.add(outerCylinder);

        // Criar geometria da mola
        const springCurve = new THREE.CatmullRomCurve3(generateHelixPoints(0.8, 0.4, 8, 65)); // Parameters: radius, height, turns, points
        const springGeometry = new THREE.TubeGeometry(springCurve, 2000, 0.0205, 8, false); // Parameters: path, tubularSegments, radius, radialSegments, closed
        const springMaterial = new THREE.MeshBasicMaterial({ color: color });
        springLine = new THREE.Mesh(springGeometry, springMaterial);

        // Posicionar a mola ao redor do cilindro
        scene.add(springLine);
        const controls = new OrbitControls(camera, renderer.domElement);

    };

    const disposeObject = (object) => {
        if (object) {
            scene.remove(object);
            object.geometry.dispose();
            object.material.dispose();
        }
    };

    // generateHelixPoints generate points for a helix shape but growing the radius linearly
    const generateHelixPoints = (radius, height, turns, numPoints) => {
        const points = [];

        let stop = (numPoints * turns) / 2;

        for (let i = -stop; i < numPoints * turns - stop; i++) {
            const angle = (i / numPoints) * Math.PI * 2 * turns;
            const x = radius * Math.cos(angle);
            const y = i * (height / numPoints);
            const z = radius * Math.sin(angle);
            points.push(new THREE.Vector3(x, y, z));
            radius -= 0.001;
        }
        return points;
    };

    const animate = () => {
        requestAnimationFrame(animate);

        // Rotacionar o cilindro externo e a mola
        if (outerCylinder && springLine) {
            outerCylinder.rotation.y += 0.03;
            springLine.rotation.y += 0.03;

            //outerCylinder.rotation.y += 0.03;
            //springLine.rotation.y += 0.03;


        }

        // Renderizar a cena
        renderer.render(scene, camera);
    };

    onMounted(() => {
        initThree(props.color);
        animate();
    });

    onBeforeUnmount(() => {
        // Limpar os recursos do Three.js
        renderer.dispose();
    });

    watch(
        () => props.trigger,
        () => {
            console.log('Trigger updated');
            // Trigger a reset of the scene when the trigger is updated
            initThree(props.color);
        },
    );
</script>

<style scoped>
    #canvasContainer {
        position: absolute;
        top: 0;
        left: 0;
    }
</style>
